#content .cl-mpp-product-layout-v1 {
  .mpp-grid {
    ul.view-large li.mpp-box {
      height: 550px;
      .product-info {
        .mpp-button-holder,
        .selectskuholder {
          margin-left: 53px;
        }
      }
    }
    li.mpp-box {
      height: 400px;
      .badge.badge_2,
      .badge.badge_16,
      .badge.badge_37,
      .badge.badge_46,
      .badge.badge_64,
      .badge.badge_85 {
        background: none repeat scroll 0 0 #5dba98;
      }
      .badge.badge_1 {
        background: none repeat scroll 0 0 #fe6cb5;
      }
      .product-info {
        height: 200px;
        .mpp-button-holder {
          @include mixn_mpp-btn-holder;
        }
        .selectskuholder {
          @include mixn_selectskuholder;
        }
      }
      .btn-shopnow {
        visibility: visible;
        margin-left: 15px;
      }
      .sbHolder {
        width: 95%;
      }
      .ie7 & {
        *display: inline;
        zoom: 1;
      }
    }
  }
  .mpp-wrapper ul.breadcrumbs {
    margin-top: 10px;
    li {
      display: inline;
    }
  }
}
/* button issue */
.block {
  .three_step_product_v2,
  h3.grey_product_title a,
  h3.grey_product_title {
    color: gray !important;
    margin: 5px 0 !important;
    font-size: 13px !important;
    line-height: 15px !important;
  }
  .selectskuholder {
    a.sbSelector {
      padding-right: 15px;
    }
  }
  a.button.button-secondary {
    padding: 3px 10px 2px;
  }
  .product-info .product-name h3 {
    font-size: 13px !important;
    line-height: 15px !important;
  }
  .promo_product_row {
    .product {
      width: 182px;
      margin-right: 12px;
      border: none;
      .btn-shopnow,
      .btn-quickview {
        display: inline-block !important;
        visibility: visible !important;
      }
      .product-image img {
        width: 181px;
        height: 209px;
      }
      .sbHolder {
        width: 95%;
      }
      .product-info {
        padding: 0 !important;
        .product-title {
          min-height: initial;
        }
      }
      .btn-shopnow {
        margin: 0px 0px 20px 0;
        font-size: 13px;
      }
      &:hover {
        .btn-quickview {
          position: static !important;
          background-color: #5dba98 !important;
          color: white;
          line-height: 1.5;
          font-size: 0.9em;
          text-align: center;
          letter-spacing: 0;
          border: 0;
          padding: 4px 10px 3px;
          display: inline-block;
          zoom: 1;
        }
        .btn-shopnow {
          font-size: 13px;
        }
        .product-details {
          border-color: white;
        }
      }
    }
  }
}

.three-step-formatter-rows,
.power-couples-formatter-rows {
  .promo_product_row {
    overflow: inherit;
    float: left;
    width: 100%;
    .product {
      height: 400px;
      .btn-shopnow {
        margin: 0;
      }
      .mpp-button-holder {
        @include mixn_mpp-btn-holder;
      }
      .selectskuholder {
        @include mixn_selectskuholder;
      }
    }
  }
}

.mascara-product-v1,
.mpp-product-v1 {
  .product {
    float: left;
    margin: 0 1% 0 0;
    min-height: 500px;
    width: 32%;
    position: relative;
    .btn-shopnow {
      display: inline-block;
      font-size: 12px;
    }
    .product-details {
      min-height: 480px;
      .mascara-title {
        white-space: nowrap;
      }
      .product-info {
        height: 180px;
        .mpp-button-holder {
          position: absolute;
          bottom: 20px;
        }
      }
    }
  }
}

.mpp-product-v1 .product {
  .btn-shopnow,
  .add-progress {
    margin: 0 0 20px 15%;
  }
  .spp_product_status {
    .temp_out_of_stock_msg,
    .coming_soon_stock_msg {
      width: 70px;
      margin-left: 45px;
    }
  }
}

.pcg-product-large-v1 {
  height: 540px;
  .mpp-box {
    height: 500px;
    .product-info {
      top: 0px;
      height: 180px;
    }
    .mpp-product-hover-view {
      display: block;
      border: none;
    }
    .btn-shopnow {
      position: absolute;
      bottom: 20px;
    }
  }
}
