.pc_spp_view {
  .goes_great_with {
    margin: 0 auto;
    max-width: 1008px;
    padding: 0 24px;
    .grid {
      .outer-wrap {
        margin: 0 !important;
      }
    }
  }
  .page-spp {
    .node-spp-related-content-item-set {
      .product-content-grid-v1 {
        .pcg-product-large-v1 {
          height: auto;
          .mpp-box {
            height: auto;
            min-height: 400px;
            .mpp-product-hover-view {
              .product-info {
                a.btn-quickview {
                  display: none;
                }
                .reviews {
                  img {
                    width: auto !important;
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .module-spp-detail {
    background: url('/media/export/images/shaded_border.jpg') repeat-x bottom center;
    padding: 0 30px 28px 0;
    margin: 0 0 20px;
    .container .col2 .abstract,
    .options .offers_msg {
      color: $color-darker-grey;
      margin-top: 7px;
    }
    .options .offers_msg span.gr {
      color: #5dba98;
    }
    .col2 {
      .price {
        font-weight: $font-weight-bold;
        margin-bottom: 10px;
      }
      .support {
        .support_live_chat {
          float: left;
          img {
            position: relative;
            bottom: 5px;
          }
        }
      }
    }
    @include icon-symbol('.active');
    @include icon-symbol(':hover');
    @include icon-symbol('');
    .add-to-bag.white-add-to-bag {
      background-color: $color-white;
      color: #5dba98;
      border: 2px solid #5dba98;
      line-height: 37px;
      margin: 0 5px;
    }
    .prod-sub-head {
      font-weight: $font-weight-normal;
      letter-spacing: 0.02em;
      font-family: 'HelveticaNeueLTStd45Light', Arial, sans-serif;
    }
    .spp-services-container ul.spec {
      margin-top: 15px;
    }
    .spp-services-container ul.spec li {
      background: url('/media/export/images/small_dot.jpg') no-repeat left center;
      padding-left: 15px;
      display: inline;
      margin-right: 12px;
    }
    .spp-share-container .BVRRPrimarySummary {
      text-align: left;
    }
    .icon-message {
      font-size: 12px;
      width: auto;
    }
    .col1 {
      .spp-share-chat-container {
        position: relative;
        margin-top: -40px;
        .BVRRSocialBookmarkingLinks {
          position: absolute;
          left: 0;
          top: 33px;
        }
        #BVRRRatingSummaryLinkWriteID {
          a {
            line-height: 1em;
          }
        }
        .we-chat {
          margin-left: 20px;
          img,
          p.wechattext {
            float: left;
          }
          p.wechattext {
            margin-top: 3px;
          }
          .qrWrapper {
            display: none;
            z-index: 999;
            position: absolute;
            padding: 20px;
            bottom: 35px;
            left: 10px;
            cursor: pointer;
            background-color: white;
            box-shadow: 0 0 3px grey;
          }
        }
        .spp-share-container {
          margin-top: 0px;
        }
      }
    }
  }
  .sticky_content {
    .selected_sku {
      span.type {
        margin-right: 5px;
        height: 20px;
      }
      div.label {
        float: left;
        &:after {
          content: '';
          clear: both;
        }
      }
    }
  }
  .spp-extra .module-may-we-suggest {
    border: none;
    ul.product-grid li.box {
      &.w_24 {
        width: 24% !important;
        float: left;
      }
    }
    .grid .product {
      a:hover,
      a:active {
        text-decortion: none;
      }
      a img {
        width: 117px;
        height: 135px;
      }
    }
  }
  .related_content_item .module-benefits {
    border-top: 1px solid #6c6c6c;
    background-color: $color-white;
  }
  .module-benefits .view-large li.box {
    width: 400px;
  }
}

.spp_reviews {
  #BVRRSecondarySummaryContainer {
    .BVRRSecondaryRatingSummaryNoReviews {
      width: 1008px;
      margin: 0 auto;
    }
    .BVDI_QTToggles {
      .BVDI_QTToggleItem {
        a#BVRRQTFilterHeaderToggleHideHistogramsID {
          margin-top: 17px;
        }
      }
    }
  }
}

.pc_spp_view .sticky_content .spp_product_status {
  clear: none;
}

.spp_page_wrap {
  .container {
    .product-full {
      &__media {
        vertical-align: top;
        display: inline-block;
        @media #{$medium-up} {
          width: 48%;
          display: inline-block;
          margin-bottom: 20px;
        }
        .product-full {
          &__image {
            display: inline-block;
            vertical-align: top;
            margin: 0 auto;
            position: relative;
            max-width: 402px;
            &-wrapper {
              display: inline-block;
              position: relative;
              vertical-align: top;
            }
            &--slide {
              width: 402px;
              height: 464px;
              @media #{$medium-up} {
                display: none;
              }
              &.active {
                @media #{$medium-up} {
                  display: block;
                }
              }
              img {
                width: 402px;
                height: 464px;
              }
            }
            &__video {
              position: relative;
              &.slick-slide {
                img {
                  &.mobile-hidden {
                    display: none;
                  }
                }
              }
              &-overlay {
                background-image: url('/media/images/icons/video_play.png');
                background-repeat: no-repeat;
                background-position: top right;
                padding: 20px;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
              }
              .poster-frame {
                width: 100%;
              }
            }
          }
          &__alt-images {
            display: inline-block;
            vertical-align: top;
            position: relative;
            &-slider {
              display: none;
              margin: 60px 20px 0 0;
              width: 50px;
              @media #{$medium-up} {
                display: none;
              }
              &.active {
                @media #{$medium-up} {
                  display: inline-block;
                }
              }
              .slick {
                &-slide {
                  border: none;
                }
                &-arrow {
                  height: 6px;
                  width: 11px;
                  left: 20px;
                  position: relative;
                  z-index: 100;
                  padding: auto;
                }
                &-prev {
                  top: -25px;
                  background: url('/media/images/icons/spp_thumb_carousel_caret_up.png') no-repeat;
                  &:before {
                    content: none;
                  }
                }
                &-next {
                  bottom: -25px;
                  background: url('/media/images/icons/spp_thumb_carousel_caret_down.png') no-repeat;
                  &:before {
                    content: none;
                  }
                }
              }
              &-button {
                height: 6px;
                width: 11px;
                margin: 0 auto;
                cursor: pointer;
              }
            }
            &-thumb {
              padding: 5px 0;
              cursor: pointer;
              border-bottom: 3px solid $color-white;
              &.active {
                border-color: $color-black;
              }
              img {
                width: 50px;
              }
            }
          }
          .badge {
            @include border-radius(50%);
            position: absolute;
            top: 50px;
            #{$ldirection}: 50px;
            display: table;
            width: 65px;
            height: 65px;
            color: $color-white;
            z-index: 1;
            padding: 0;
            line-height: 14px;
            font-family: $base-font-family;
            vertical-align: middle;
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            &--large {
              top: 0;
              left: 0;
            }
            &.badge_1,
            &.badge_2,
            &.badge_3,
            &.badge_4,
            &.badge_5,
            &.badge_17,
            &.badge_92,
            &.badge_94,
            &.badge_96,
            &.badge_97 {
              background: $color-cl-grey;
            }
            &.badge_96 {
              font-size: 12px;
            }
            &.badge_30 {
              background: $color-cl-grey;
            }
            html.ie7 & {
              text-align: center;
            }
            .badge-inner {
              display: table-cell;
              vertical-align: middle;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.sticky_prod_select {
  .giftset_components {
    .product-size {
      &__non-shaded {
        display: none;
      }
    }
  }
}

[data-component] {
  .elc-button {
    div {
      font-family: $base-font-family-roman;
    }
  }
}
