//colors
$color_hot_cinnamon_approx: #e7651e;
$color_black_approx: #010101;
$color_silver_chalice_approx: #aaaaaa;

div {
  &#map {
    margin-bottom: 20px;
    margin-left: 10px;
    height: 350px;
    width: 1000px;
  }
  &.locator_content h2 {
    color: #e7651e;
    font-size: 28px;
  }
  &.store-locator-body #store-search-controls {
    .door_container {
      margin-left: 30px;
    }
    .search_container {
      margin-left: 30px;
      a.button {
        font-size: 13px;
      }
    }
  }
}

#foreground-node {
  .directions_panel {
    width: 800px;
    height: auto;
    overflow: hidden;
    margin: 0;
    .close_container {
      margin: 5px 0;
      float: right;
    }
    .directions_form {
      float: left;
      width: 185px;
      margin: 0 0 0 20px;
      .store_locator {
        font-size: 12px;
        color: $color_black_approx;
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: solid 1px $color_silver_chalice_approx;
      }
      .destination_container {
        width: 100%;
        padding: 0;
        h4 {
          font-size: 12px;
          margin-bottom: 15px;
          padding-bottom: 10px;
          border-bottom: solid 1px $color_silver_chalice_approx;
          border-top: none;
        }
        .destination {
          margin-bottom: 10px;
          margin-top: 5px;
          padding-left: 0;
        }
      }
      fieldset.fs {
        width: auto;
        padding: 0;
        border: none;
        .form_element {
          width: auto;
          float: none;
          margin: 5px 15px 10px 0;
          label {
            font-weight: normal;
            margin: 0 0 6px;
            float: left;
            width: 100%;
          }
          input {
            border: solid 1px $color_silver_chalice_approx;
          }
        }
      }
    }
    .directions_map_container {
      float: right;
      width: 535px;
      margin: 0 20px 0 0;
    }
    #baidu_directions_results {
      float: left;
      border: none;
      margin-top: 40px;
      width: 100%;
      table {
        width: 100%;
      }
    }
    h2.directions-header {
      font-size: 13px;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      height: 50px;
      line-height: 50px;
      margin: 0;
      padding: 0;
    }
    a.directions_submit {
      background: none repeat scroll 0 0 $color-black;
      border: 0 none;
      color: $color-white;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      line-height: 17px;
      padding: 3px 10px;
      text-align: center;
      text-decoration: none;
      height: 21px;
    }
  }
  .panel_close {
    position: absolute;
    right: 20px;
    top: 20px;
    background: url('/media/images/global/buttons/spp-help-close.png') no-repeat scroll 0 0 transparent;
    width: 9px;
    height: 9px;
    text-indent: -9999px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
}
