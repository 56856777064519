@mixin english-font {
  font-family: 'HelveticaNeueLTStd-UltLt', Arial, Sans-Serif !important;
}

@mixin font-global {
  font-family: 'Microsoft YaHei', 'Microsoft JhengHei', ”Hiragino Sans GB”, ”STHeiti”, verdana, Tahoma, arial;
}

body,
td,
th,
p,
a,
div,
span,
input,
#pg_wrapper select,
#navigation,
#footer {
  @include font-global;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-global;
}

.top-left,
.top-right,
a.button,
strong,
b,
form label,
fieldset span,
a.btn-add-to-bag {
  @include font-global;
}
/* header start */

#top {
  .offer-promo-v1 .headline,
  .col li a,
  .col h2 {
    @include font-global;
  }
  .gnav-search-v1 form .form-text {
    @include font-global;
  }
}

#header ul li a {
  @include font-global;
}
/* header end */
/* footer start */

#footer {
  .footer-top-v1 {
    .tel {
      @include font-global;
    }
    a {
      @include font-global;
    }
  }
  .footer-bottom .block a {
    @include font-global;
  }
}
/* footer end */
/* homepage start */

.vertical-carousel-navigation-v1 {
  .fwp-3-4-1-4-image-content-v1 .content .subtitle p {
    @include font-global;
  }
  .full-width-2-row-v1 .subheading {
    @include font-global;
    p {
      @include font-global;
    }
  }
  .video-carousel-v1 {
    .video-thumb .video-title {
      @include font-global;
    }
    .video-main .video-title {
      @include font-global;
    }
  }
}

#gnav_signin .signin_container form label {
  @include font-global;
}
/* homepage end */
/* mpp start */

.cl-mpp-product-layout-v1 {
  .mpp-grid ul.view-small li.mpp-box {
    h3 {
      @include font-global;
    }
    a h3.grey_product_title {
      @include english-font;
      color: #231f20 !important;
    }
    p.price {
      @include font-global;
    }
  }
  .mpp-grid ul.view-large li.mpp-box {
    a h3.grey_product_title {
      @include english-font;
      color: #231f20 !important;
      font-size: 15px !important;
    }
  }
  .mpp-filter {
    h1 span,
    h3 span {
      @include font-global;
    }
    div.mpp-filter-subheaders {
      h2 {
        @include font-global;
      }
    }
  }
}
/* mpp end */
/* spp start */
.pc_spp_view {
  .module-spp-detail {
    .shade-info strong,
    .container .col2 .support {
      @include font-global;
    }
  }
  .BVRRSecondaryRatingSummaryCustomHeader {
    @include font-global;
  }
  .module-may-we-suggest {
    .grid {
      ul.product-grid li.box .product a h3 {
        @include font-global;
      }
    }
  }
  .page-spp .breadcrumbs li a {
    @include font-global;
  }
}
/* spp end */
/* Myaccount start */

.my-account-auth-user-panel-v1 {
  .twocol .avatar .bio span.name {
    @include font-global;
  }
  .shortcuts li a {
    @include font-global;
  }
  .card h3,
  .my-account-past-purchases-v1 a.button {
    @include font-global;
  }
}

.shipping-billing .section-column .section-header {
  @include font-global;
}

.favorite-product .price {
  @include font-global;
}

.prod-shelf-row .info ul li {
  @include font-global;
}

.registration_content {
  #required-fields,
  .welcome-msg,
  .photo-form-container {
    @include font-global;
  }
}
/* Myaccount end */
/* checkout start */

#main.multi {
  #sidebar p,
  .section-header,
  #content h2,
  .error .server {
    @include font-global;
  }
}

body {
  .wrapper {
    .specificfont {
      @include font-global;
    }
    #header {
      .gnav-search-v1 {
        .search-term {
          @include font-global;
        }
      }
    }
    .summary-search-form {
      input.search-term {
        @include font-global;
      }
    }
  }
}
/* checkout end */
