/* Power Review */

#pr-spp-snippet {
  .p-w-r {
    .pr-snippet {
      padding-bottom: 5px;
    }
  }
}

#pr-review-display {
  .p-w-r {
    .pr-review-snapshot {
      .pr-ratings-histogram {
        .pr-ratings-histogram-bar-container {
          padding-left: 8px;
        }
      }
    }
    .pr-review-snapshot-simple {
      .pr-review-snapshot-block {
        &.pr-review-snapshot-block-histogram {
          padding: 20px 10px;
        }
      }
    }
    .pr-multiselect {
      .pr-multiselect-options {
        ul {
          width: 230px;
        }
      }
    }
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: '是';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: '否';
              }
            }
          }
          .pr-rd-flag-review-container {
            width: auto;
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          width: 50%;
          &:before {
            content: '筛选:';
          }
        }
        .pr-rd-review-header-sorts {
          width: 61%;
          .pr-rd-sort-group {
            float: right;
          }
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-sort-group {
          &:before {
            content: '排序方式:';
          }
        }
      }
      .pr-rd-sort-group {
        &:before {
          content: '排序方式:';
        }
      }
      .pr-multiselect {
        .pr-multiselect-button {
          padding: 8px 29px 8px 1px;
          margin: 5px 10px 5px 0;
        }
      }
    }
    #pr-image-display {
      display: none;
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .pr-media_image-form-group {
        display: block;
      }
    }
  }
}
/* Font Impementation */
#pr-write,
#pr-review-display,
.spp_page_wrap,
.spp_reviews,
#pr-reviewsnippet {
  .p-w-r {
    a {
      @include font-global;
    }
    .pr-review-snapshot-header-intro {
      .pr-headline {
        @include font-global;
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline {
        @include font-global;
      }
    }
  }
}

.mpp-grid,
.mpp-product {
  .p-w-r {
    .pr-category-snippet__total {
      @include font-global;
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          @include font-global;
        }
      }
    }
  }
}
/* SPP page */
#pr-spp-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        a,
        span {
          @include font-global;
        }
      }
    }
  }
}

.reviews {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        a,
        span {
          @include font-global;
        }
      }
    }
  }
}
/* To display a Review description posted by user */
.pwr_title,
#BVRRPullquoteHighlightContainer {
  @include font-global;
}

#pr-review-display {
  .p-w-r {
    .pr-review-snapshot-simple {
      .pr-snippet-read-and-write {
        a.pr-snippet-write-review-link {
          @include font-global;
        }
      }
      .pr-review-snapshot-snippets-headline {
        @include font-global;
      }
      .pr-review-snapshot-snippets {
        .pr-snippet-review-count {
          @include font-global;
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-header {
        .pr-review-snapshot-snippets {
          .pr-snippet-stars-reco-stars {
            .pr-snippet-read-and-write {
              .pr-snippet-write-review-link {
                @include font-global;
              }
            }
          }
        }
      }
      .pr-snippet-stars-reco-inline {
        .pr-snippet-read-and-write {
          .pr-snippet-review-count {
            @include font-global;
          }
        }
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-sort {
          @include font-global;
        }
      }
      .pr-review {
        .pr-rd-header {
          .pr-rd-review-headline {
            @include font-global;
          }
        }
        .pr-rd-description {
          .pr-rd-description-text {
            @include font-global;
          }
          .pr-rd-right {
            .pr-rd-reviewer-details {
              p,
              time {
                @include font-global;
              }
              p {
                .pr-rd-bold {
                  @include font-global;
                }
              }
            }
          }
        }
        .pr-rd-merchant-response {
          .pr-rd-description-text {
            @include font-global;
          }
          .pr-rd-merchant-response-headline {
            strong {
              @include font-global;
            }
          }
        }
        .pr-accordion {
          .pr-accordion-btn {
            span {
              @include font-global;
            }
          }
          .pr-accordion-content {
            dl {
              dt,
              dd {
                @include font-global;
              }
            }
          }
        }
        .pr-rd-footer {
          .pr-rd-bottomline {
            span {
              @include font-global;
            }
          }
          .pr-rd-helpful-action {
            .pr-rd-helpful-text {
              @include font-global;
            }
          }
          .pr-helpful-count {
            @include font-global;
          }
          .pr-rd-flag-review-container {
            @include font-global;
          }
          .pr-modal-content {
            #pr-flag-reviews {
              .pr-flagging-radio {
                span {
                  @include font-global;
                }
              }
            }
            .pr-flag-review-btn {
              @include font-global;
            }
            .pr-control-label,
            .pr-flag-review-label {
              @include font-global;
            }
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          .pr-rd-to-top {
            @include font-global;
          }
          .pr-rd-pagination-btn,
          .pr-rd-review-position span {
            @include font-global;
          }
        }
      }
    }
    .pr-snippet-reco-to-friend {
      .pr-reco-to-friend-message {
        @include font-global;
      }
      .pr-reco {
        span {
          @include font-global;
        }
      }
    }
  }
}
/* Write a Review Form */
#power_review_container {
  .product-full__name {
    @include font-global;
  }
  .pr-sub-line {
    @include font-global;
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .pr-header {
        .pr-header-title {
          @include font-global;
        }
      }
      .form-control {
        @include font-global;
      }
      .pr-footer {
        .pr-subscript {
          @include font-global;
        }
      }
      .thank-you-page {
        .header {
          .title,
          .subtitle {
            @include font-global;
          }
        }
        .pr-attribute {
          @include font-global;
        }
        .pr-attribute-header {
          @include font-global;
        }
        .pr-dark {
          @include font-global;
        }
        .pr-text {
          @include font-global;
          span {
            @include font-global;
          }
        }
      }
    }
    .form-group {
      span,
      label,
      .pr-helper-text {
        @include font-global;
      }
    }
  }
}

.spp_reviews {
  display: none;
}
